import './Welcome.css'

const Welcome = () => { 

  return (
    <div id='welcome'>
      <h1>¡Bienvenido</h1>
      <p>al panel de administrador</p>  
     
      <div className="circleA"></div>
      <div className="circleB"></div>
    </div>
  )
}

export default Welcome