import { useContext, useEffect, useState } from "react"
import LanguageContext from "../../../../context/languageContext"
import { Link } from "react-router-dom"

const MenuMobile = ({desactive, setResumeActive}) => {
  const {language} = useContext (LanguageContext)
  const [lang, setLang] = useState()  

  useEffect(() => {
    setLang(language)    
  }, [language])

  return (
    <>
    <div className="menuMobile__background" onClick={desactive}></div>
    
    <div className="menuMobile">     

      <a href="#actions" className="linkedin-icon" onClick={desactive}> {lang === "esp" ? "Soluciones" : "Solutions"}</a>
      <a href="#style" className="linkedin-icon" onClick={desactive}> {lang === "esp" ? "¿Por qué Modus?" : "Why Modus?"}</a>
      <a href="#formulario" className="linkedin-icon" onClick={desactive}> {lang === "esp" ? "Contacto" : "Contact"}</a>
      <h3 onClick={setResumeActive}> {lang === "esp" ? "Unite al equipo" : "Join the team"}</h3>
      <Link to="/profile"> {lang === "esp" ? "Perfil" : "Profile"} </Link>  
      
    </div>
    </>
  )
}

export default MenuMobile